import { Injectable } from '@angular/core'
import {
    loadDynamicMedia,
    loadDynamicMediaSuccess,
    loadDynamicMediaError,
    loadMultiDynamicMediaSuccess,
    LoadDynamicMediaParams
} from '@bh/design-system'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators'
import { MediaService } from '../services/media.service'
import {
    loadMultipleMedia,
    loadMultipleMediaError,
    loadMultipleMediaSuccess
} from './media.actions'
import { Attachment } from '../models/media'

@Injectable()
export class MediaEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private mediaService: MediaService
    ) {
        this.logger = this.loggerFactory.getLogger(MediaEffects.name)
    }

    loadDynamicMedia = createEffect(() =>
        this.actions.pipe(
            ofType(loadDynamicMedia),
            tap(({ id }) => this.logger.debug(`Starting to load dynamic media for id ${id}`)),
            mergeMap(({ id, thumbnail }) =>
                this.mediaService.getMediaUrl(id, thumbnail).pipe(
                    tap((media) => this.logger.debug('Media URL loaded', media)),
                    map((media) => {
                        return loadDynamicMediaSuccess({
                            id,
                            url: media.url,
                            contentType: media.contentType || 'image',
                            thumbnail,
                            fileName: media.fileName,
                            thumbnailShortContentType: media.thumbnailShortContentType,
                            reactions: media.reactions
                        })
                    }),
                    catchError((error: Error) => {
                        this.logger.error(`Error loading dynamic media ${id}`, error.message, error)
                        return of(loadDynamicMediaError({ error }))
                    })
                )
            )
        )
    )

    loadMultipleMedia = createEffect(() =>
        this.actions.pipe(
            ofType(loadMultipleMedia),
            tap(({ attachmentIds, thumbnail, date }) => {
                this.logger.debug(
                    `Starting to load multiple media media attachmentIds ${attachmentIds} is thumbnail ${thumbnail} ${date}`
                )
            }),
            filter(({ attachmentIds }) => attachmentIds?.length > 0),
            mergeMap(({ attachmentIds, thumbnail }) =>
                this.mediaService.getAllAttachments(thumbnail, attachmentIds).pipe(
                    tap((attachments) => this.logger.debug('Media URL loaded', attachments)),
                    mergeMap((attachments: Attachment[]) => {
                        const multiDynamicMediaData: LoadDynamicMediaParams[] = attachments.map(
                            (attachment) => {
                                return {
                                    id: attachment.attachmentId,
                                    url: attachment.url,
                                    contentType: attachment.contentType || 'image',
                                    thumbnail,
                                    fileName: attachment.fileName,
                                    thumbnailShortContentType: attachment.thumbnailShortContentType,
                                    reactions: attachment.reactions
                                }
                            }
                        )

                        if (multiDynamicMediaData.length > 0) {
                            return [
                                loadMultiDynamicMediaSuccess({ multiDynamicMediaData }),
                                loadMultipleMediaSuccess({ attachmentIds, thumbnail })
                            ]
                        }
                        return [
                            loadMultiDynamicMediaSuccess({ multiDynamicMediaData }),
                            loadMultipleMediaSuccess({ attachmentIds, thumbnail })
                        ]
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error loading multiple media media attachmentIds ${attachmentIds} is thumbnail ${thumbnail}`
                        )

                        return [loadMultipleMediaError({ error })]
                    })
                )
            )
        )
    )
}
