import { Inject, Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { Notification } from '../models/notification'
import { NotificationService } from '../services/notification.service'
import { loadingStarted, loadingDone } from '../loading/loading.actions'
import {
    listNotifications,
    listNotificationsError,
    listNotificationsSuccess,
    markNotificationAsDeleted,
    markNotificationAsDeletedError,
    markNotificationAsDeletedSuccess,
    updateNotificationReadStatus,
    updateNotificationReadStatusError,
    updateNotificationReadStatusSuccess
} from './notification.actions'
import  { DBService } from '@bh/data'

@Injectable()
export class NotificationEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private notificationService: NotificationService,
		@Inject(DBService) private dbService: DBService,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger(NotificationEffects.name)
    }

    listNotifications = createEffect(() =>
        this.actions.pipe(
            ofType(listNotifications),
            tap(({ guardianId }) => {
                this.logger.debug(`Starting to list notifications for guardian ${guardianId}`)
                this.store.dispatch(loadingStarted({ model: 'Notification' }))
            }),
            mergeMap(({ guardianId }) =>
                this.notificationService.getNotifications(guardianId).pipe(
                    tap((notificationList: Notification[]) =>
                        this.logger.debug('Notifications loaded', notificationList)
                    ),
                    mergeMap((notificationList: Notification[]) => [
                        listNotificationsSuccess({ notificationList }),
                        loadingDone({ model: 'Notification' })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error('Error listing notifications', error.message, error)
                        this.store.dispatch(loadingDone({ model: 'Notification' }))
                        return of(listNotificationsError({ error }))
                    })
                )
            )
        )
    )

    updateNotificationReadStatus = createEffect(() =>
        this.actions.pipe(
            ofType(updateNotificationReadStatus),
            tap(({ guardianId, notificationId, readInMessages, readInReminders, isRead }) =>
                this.logger.debug(
                    `Starting to mark notification ${notificationId} for guardian ${guardianId} with read status: ${isRead} ${readInMessages} ${readInReminders} `
                )
            ),
            mergeMap(({ guardianId, notificationId, isRead, readInMessages, readInReminders }) =>
                this.notificationService
                    .updateNotificationReadStatus(
                        guardianId,
                        notificationId,
                        readInMessages,
                        readInReminders,
                        isRead
                    )
                    .pipe(
                        tap(() =>
                            this.logger.debug('Notification read status updated with success')
                        ),
                        map(() => 
							updateNotificationReadStatusSuccess({
                                notificationId,
                                isRead,
                                readInMessages,
                                readInReminders
                            }),
							this.dbService.deleteCacheByKey('apiCache',`notificationList_${guardianId}`)
                        ),
                        catchError((error: Error) => {
                            this.logger.error(
                                'Error marking notification read status',
                                error.message,
                                error
                            )
                            return of(updateNotificationReadStatusError({ error }))
                        })
                    )
            )
        )
    )
    markNotificationAsDeleted = createEffect(() =>
        this.actions.pipe(
            ofType(markNotificationAsDeleted),
            tap(
                ({
                    guardianId,
                    notificationId,
                    isRead,
                    showInMessages,
                    showInReminders,
                    showInHomepage,
                    readInMessages,
                    readInReminders
                }) =>
                    this.logger.debug(
                        `Starting to mark notification ${notificationId} for guardian ${guardianId} with field ${isRead}, Show in Messages: ${showInMessages}, Show In Reminders: ${showInReminders}, Homepage: ${showInHomepage}, Read in Message: ${readInMessages}, Read in Reminder ${readInReminders}, as deleted`
                    )
            ),
            mergeMap(
                ({
                    guardianId,
                    notificationId,
                    isRead,
                    showInMessages,
                    showInReminders,
                    showInHomepage,
                    readInMessages,
                    readInReminders
                }) =>
                    this.notificationService
                        .markNotificationAsDeleted(
                            guardianId,
                            notificationId,
                            isRead,
                            showInMessages,
                            showInReminders,
                            showInHomepage,
                            readInMessages,
                            readInReminders
                        )
                        .pipe(
                            tap(() => this.logger.debug('Notification marked as deleted')),
                            map(() => 
								markNotificationAsDeletedSuccess({ notificationId }),
								this.dbService.deleteCacheByKey('apiCache',`notificationList_${guardianId}`)
                            ),
                            catchError((error: Error) => {
                                this.logger.error(
                                    'Error marking notification as deleted',	
                                    error.message,
                                    error
                                )
                                return of(markNotificationAsDeletedError({ error }))
                            })
                        )
            )
        )
    )
}
