import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { FullstoryService, LoggerFactory, LoggerService } from '@bh/logging'
import { Preferences } from '@capacitor/preferences'
import { AppInitService } from '../../../../app/src/app/app-init.service'
import { Observable, from, fromEvent, of } from 'rxjs'
import { concatMap, map, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators'
import { FeatureService, setConsent } from '@bh/security'
import { environment } from '../../../../app/src/environments/environment'
import { Store } from '@ngrx/store'

declare global {
    interface Window {
        OneTrust: {
            startSDK: (
                storageIdentifier: string,
                id: string,
                langCode: string,
                params: any,
                onSuccess: (status: any) => void,
                onError: (error: any) => void
            ) => void
            showBannerUI: () => void
            showPreferenceCenterUI: () => void
            shouldShowBanner: (callback: (shouldShow: boolean) => void) => void
            getCachedIdentifier: (callback: (identifier: any) => void) => void
            getPreferenceCenterData: (callback: (data: any) => void) => void
            getBannerData: (callback: (data: any) => void) => void
            getConsentStatusForCategory: (
                categoryId: string,
                callback: (status: number) => void
            ) => void
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class ConsentBannerService {
    public static readonly CONSENT_BANNER = 'consentBanner'
    public CONSENT_CATEGORIES = [
        'Strictly Necessary',
        'Performance',
        'Functional',
        'Targeted Advertising / Social Media'
    ]
    private logger: LoggerService
    public static readonly PERFORMANCE_CONSENT_CODE = 'C0002'

    constructor(
        private platform: Platform,
        private loggerFactory: LoggerFactory,
        private featureService: FeatureService,
        private fullStoryService: FullstoryService,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger(ConsentBannerService.name)
    }

    public startSDK() {
        if (this.isConsentBannerEnabled()) {
            const iosID = environment.config().consentBanner.iosID
            const androidID = environment.config().consentBanner.androidID
            const storageIdentifier = environment.config().consentBanner.storageIdentifier
            const langCode = environment.config().consentBanner.languageCode

            let id = ''

            from(Preferences.get({ key: AppInitService.STORAGE_CURRENT_ENV }))
                .pipe(take(1))
                .subscribe((currentEnv) => {
                    /* 
                        If the the user is in prod or no env value exists, use the prod OneTrust ID 
                        If the user is in a lower environment, use the test OneTrust ID
                    */
                    if (!currentEnv.value || currentEnv.value === 'prod') {
                        id = this.platform.is('ios') ? iosID : androidID
                    } else {
                        id = this.platform.is('ios') ? `${iosID}-test` : `${androidID}-test`
                    }

                    window.OneTrust.startSDK(
                        storageIdentifier,
                        id,
                        langCode,
                        null,
                        (sdkStatus: boolean) => {
                            /* Performance is the only cookie the user may be able to turn off, we track the changes and update local storage if consent changes */
                            this.observeChanges()
                            this.checkFullStoryStatus()

                            this.shouldShowBanner().then((shouldShowBanner: boolean) => {
                                if (sdkStatus && shouldShowBanner) {
                                    this.showBannerUI()
                                    this.logger.debug(
                                        'OneTrust SDK Initialized and showing consent banner'
                                    )
                                }
                            })
                        },
                        (error: Error) => {
                            this.logger.error('OneTrust SDK Failed to initialize! Error = ' + error)
                        }
                    )
                })
        }
    }

    /* Function returns an array of current cookie categories with their consents */
    public getCurrentConsents(): Observable<ConsentBannerCategory[]> {
        return from(this.getCurrentCookies()).pipe(
            mergeMap((consentCategories) =>
                from(consentCategories).pipe(
                    mergeMap((category) =>
                        this.getCategoryConsent(category.cookieID).pipe(
                            map(
                                (consent) =>
                                    ({
                                        cookieType: category.cookieType,
                                        cookieID: category.cookieID,
                                        consentGiven: consent > 0
                                    } as ConsentBannerCategory)
                            )
                        )
                    )
                )
            ),
            toArray()
        )
    }

    /* Function returns an array of current cookie categories available */
    public async getCurrentCookies(): Promise<ConsentBannerCategory[]> {
        const preferenceData = await this.getPreferenceData()

        const cookieCategories: ConsentBannerCategory[] = []

        preferenceData?.Groups.forEach((category: any) => {
            cookieCategories.push({
                cookieType: category?.GroupName,
                cookieID: category?.CustomGroupId
            })
        })

        return cookieCategories
    }

    /* Function to observe changes to cookies and update local storage */
    public observeChanges() {
        fromEvent(document, 'allSDKViewsDismissed')
            .pipe(
                switchMap(() => this.getCurrentConsents()),
                concatMap((currentConsent) =>
                    from(
                        Preferences.set({
                            key: ConsentBannerService.CONSENT_BANNER,
                            value: JSON.stringify(currentConsent)
                        })
                    )
                ),
                tap(() => this.checkFullStoryStatus()),
                switchMap(() => (this.getPerformanceConsent()))
            )
            .subscribe((analyticsConsent) => {
                this.store.dispatch(setConsent({ analyticsConsent: analyticsConsent!== false }))
            })
    }

    /* Function takes in a categoryID and returns -1, 0, or 1.
        1 = consent given. 0 = consent not given. -1 = No SDK or no consent  */
    public getCategoryConsent(categoryID: string): Observable<any> {
        return from(
            new Promise((resolve) => {
                window.OneTrust.getConsentStatusForCategory(categoryID, (status: number) => {
                    resolve(status)
                })
            })
        )
    }

    /* Function to get the users current Performance Consent Status */
    /* True = User has given consent, False = User has not given consent, null = User has not done consent yet */
    public getPerformanceConsent(): Observable<boolean | null> {
        return from(Preferences.get({ key: ConsentBannerService.CONSENT_BANNER })).pipe(
            take(1),
            switchMap((consentStatus) => {
                if (!consentStatus.value) {
                    return of(null)
                } else {
                    try {
                        const performanceConsent: ConsentBannerCategory[] = JSON.parse(
                            consentStatus.value
                        )

                        if (performanceConsent) {
                            const performanceCookieID = performanceConsent.find(
                                (a: ConsentBannerCategory) =>
                                    a.cookieType.toLowerCase() ===
                                    this.CONSENT_CATEGORIES[1].toLowerCase()
                            )

                            return this.getCategoryConsent(
                                performanceCookieID?.cookieID ?? ConsentBannerService.PERFORMANCE_CONSENT_CODE
                            ).pipe(
                                map((result) => {
                                    return result === 1
                                })
                            )
                        }
                    } catch (error) {
                        this.logger.error('Error getting performance consent status: ' + error)
                        return of(null)
                    }

                    return of(null)
                }
            })
        )
    }

    public checkFullStoryStatus(): void {
        this.getPerformanceConsent()
            .pipe(take(1))
            .subscribe((consent) => {
                if (consent === false) {
                    this.fullStoryService.shutdownSession()
                }
            })
    }

    /* Function to show the consent banner to the user */
    public showBannerUI(): void {
        window.OneTrust.showBannerUI()
    }

    /* Function to show the prefences center banner to the user */
    public showPreferenceCenterUI(): void {
        window.OneTrust.showPreferenceCenterUI()
    }

    /* Function returns the users current selection data on the consent banner */
    public getBannerData(): Promise<any> {
        return new Promise((resolve) => {
            window.OneTrust.getBannerData((data: any) => {
                resolve(data)
            })
        })
    }

    /* Function returns the users current preference center data */
    public getPreferenceData(): Promise<any> {
        return new Promise((resolve) => {
            window.OneTrust.getPreferenceCenterData((data: any) => {
                resolve(data)
            })
        })
    }

    /* Function retrieves the locally stored unique ID for the user */
    public retrieveCachedData(): Promise<any> {
        return new Promise((resolve) => {
            window.OneTrust.getCachedIdentifier((identifier: any) => {
                resolve(identifier)
            })
        })
    }

    /* Function returns a boolean value determining if we should show the banner to the user */
    public shouldShowBanner(): Promise<boolean> {
        return new Promise((resolve) => {
            window.OneTrust.shouldShowBanner((shouldShow: boolean) => {
                resolve(shouldShow)
            })
        })
    }

    /* Function returns a boolean value determining if the banner is enabled */
    public isConsentBannerEnabled(): boolean {
        return (
            this.featureService.featureIsEnabled('consentBanner') && this.platform.is('capacitor')
        )
    }
}

export interface ConsentBannerCategory {
    cookieType: string
    cookieID: string
    consentGiven?: boolean
}
