/* eslint-disable no-useless-escape */
import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { clickBottomNav, resetSelected, selectItem, pullToRefresh } from '@bh/design-system'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { from } from 'rxjs'
import { map, mergeMap, switchMap, tap, filter, delay } from 'rxjs/operators'
import {
    bottomNavNavigationCompleted,
    domainsClicked,
    linkClicked,
    messagesClicked,
    settingsClicked
} from './nav.actions'
import { refreshData, resetDomainFilter, resetStageFilter } from '@events'

@Injectable()
export class NavEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger(NavEffects.name)
    }

    handleNavBottomClick = createEffect(() =>
        this.actions.pipe(
            ofType(clickBottomNav),
            filter(() => !!this.activatedRoute.firstChild),
            tap((item) => this.logger.debug(`Navigating to nav item ${JSON.stringify(item)}`)),
            mergeMap(({ data }) =>
                this.activatedRoute.firstChild!.params.pipe(
                    filter((params) => !!params.guardianId),
                    switchMap((params) => {
                        return from(
                            this.router.navigate(['home', params.guardianId, data], {
                                queryParams: {
                                    notificationDate: moment().format('YYYY-MM-DD')
                                }
                            })
                        ).pipe(
                            tap((navigated) =>
                                this.logger.debug(`Navigation result: ${navigated}`)
                            ),
                            map(() => bottomNavNavigationCompleted())
                        )
                    })
                )
            )
        )
    )

    handleIconClick = createEffect(() =>
        this.actions.pipe(
            ofType(selectItem),
            filter((selected) => selected.item === 'message' || selected.item === 'new-message'),
            tap(() => this.logger.debug(`Navigating to messages page`)),
            mergeMap(() =>
                from(this.router.navigate(['/messages'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => messagesClicked())
                )
            )
        )
    )

    handleSettingsClick = createEffect(() =>
        this.actions.pipe(
            ofType(selectItem),
            filter((selected) => selected.item === 'profile'),
            tap(() => this.logger.debug(`Navigating to settings page`)),
            mergeMap(() =>
                from(this.router.navigate(['/settings/profile'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => settingsClicked())
                )
            )
        )
    )

    handleDomainClick = createEffect(() =>
        this.actions.pipe(
            ofType(selectItem),
            filter((selected) => selected.item === 'domains'),
            tap(() => this.logger.debug(`Navigating to domains page`)),
            mergeMap(() => {
                return from(this.router.navigate(['/developmental-observations/domains'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => domainsClicked())
                )
            })
        )
    )

    handleLinkClick = createEffect(() =>
        this.actions.pipe(
            ofType(selectItem),
            filter((selected) => selected.item === 'link'),
            mergeMap(() =>
                from(
                    this.router.navigate([
                        '/center-info',
                        this.router.routerState?.snapshot.url
                            .match(/\/(\w{24})/g)
                            ?.toString()
                            .replace('/', '')
                    ])
                ).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}}`)),
                    map(() => linkClicked())
                )
            )
        )
    )

    handleFilterClick = createEffect(() =>
        this.actions.pipe(
            ofType(selectItem),
            filter((selected) => selected.item === 'filter'),
            mergeMap(() =>
                from(this.router.navigate(['/messages/filters'])).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}}`)),
                    map(() => linkClicked())
                )
            )
        )
    )

    handleReset = createEffect(() =>
        this.actions.pipe(
            ofType(selectItem),
            filter(
                (selected) =>
                    selected.item === 'filter' &&
                    this.router.routerState?.snapshot.url.includes('filters')
            ),
            switchMap(() =>
                from(
                    this.router.navigate(['/messages/filters'], { skipLocationChange: true })
                ).pipe(
                    tap(() => this.store.dispatch(resetSelected())),
                    map(() => linkClicked())
                )
            )
        )
    )

    handleObservationsReset = createEffect(() =>
        this.actions.pipe(
            ofType(selectItem),
            filter(
                (selected) =>
                    selected.item === 'dev-filter' &&
                    this.router.routerState?.snapshot.url.includes('filters')
            ),
            switchMap(() =>
                from(
                    this.router.navigate(['/developmental-observations/filters'], { skipLocationChange: true })
                ).pipe(
                    tap(() => {
                        this.store.dispatch(resetSelected())
                        this.store.dispatch(resetDomainFilter())
                        this.store.dispatch(resetStageFilter())
                    }),
                    map(() => linkClicked())
                )
            )
        )
    )

    handleRefresh = createEffect(
        () =>
            this.actions.pipe(
                ofType(pullToRefresh),
                delay(100),
                tap(() => {
                    this.logger.debug(`Requesting to refresh data`)
                    this.store.dispatch(refreshData())
                })
            ),
        { dispatch: false }
    )
}
