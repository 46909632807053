import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Keyboard } from '@capacitor/keyboard'
import { Platform } from '@ionic/angular'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { RouterNavigatedAction, ROUTER_NAVIGATED } from '@ngrx/router-store'
import { filter, tap } from 'rxjs/operators'

@Injectable()
export class RoutingEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private platform: Platform
    ) {
        this.logger = this.loggerFactory.getLogger(RoutingEffects.name)
    }

    closeKeyboard = createEffect(
        () =>
            this.actions.pipe(
                ofType(ROUTER_NAVIGATED),
                filter(() => this.platform.is('capacitor')),
                tap((e: RouterNavigatedAction) =>
                    this.logger.debug(`Closing keyboard due to navigation`, e.payload)
                ),
                tap(() => Keyboard.hide())
            ),
        { dispatch: false }
    )
}
