import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { LoginComponent, ProfileGuard } from '@login'
import { HeaderGuard, NavWrapperComponent, ShellGuard } from '@nav'

const routes: Routes = [
    {
        path: 'login',
        canDeactivate: [ProfileGuard],
        canActivate: [HeaderGuard],
        data: { headerColor: 'blue' },
        component: LoginComponent
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'arrival',
        loadChildren: () => import('./modules/arrival.module').then((m) => m.ArrivalpModule),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'health-check',
        loadChildren: () =>
            import('./modules/health-check.module').then((m) => m.HealthCheckModule),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'mark-absence',
        loadChildren: () =>
            import('./modules/mark-absence.module').then((m) => m.MarkAbsenceModule),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'home/:guardianId',
        component: NavWrapperComponent,
        children: [
            {
                path: 'activity-feed',
                loadChildren: () => import('./modules/home.module').then((m) => m.HomeModule),
                data: {
                    quickActions: true
                }
            },
            {
                path: 'calendar',
                loadChildren: () =>
                    import('./modules/calendar.module').then((m) => m.CalendarModule),
                data: {
                    quickActions: false
                }
            },
            {
                path: 'memories',
                loadChildren: () =>
                    import('./modules/memories.module').then((m) => m.MemoriesModule),
                data: {
                    quickActions: false
                }
            },
            {
                path: 'my-learners',
                loadChildren: () =>
                    import('./modules/my-learners.module').then((m) => m.MyLearnersModule),
                data: {
                    quickActions: false
                }
            },
            {
                path: '**',
                redirectTo: 'activity-feed'
            }
        ],
        data: {
            headerColor: 'blue'
        },
        canActivate: [ShellGuard, HeaderGuard]
    },
    {
        path: 'center-info/:id',
        loadChildren: () => import('./modules/center-info.module').then((m) => m.CenterInfoModule),
        data: {
            headerColor: 'white'
        },
        canActivate: [HeaderGuard]
    },
    {
        path: 'pdf-viewer/:url/:title/:filename',
        loadChildren: () => import('./modules/pdf-viewer.module').then((m) => m.PdfViewModule),
        data: {
            headerColor: 'white'
        },
        canActivate: [HeaderGuard]
    },
    {
        path: 'arrival-notes',
        loadChildren: () =>
            import('./modules/arrival-notes.module').then((m) => m.ArrivalNotesModule),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'messages',
        loadChildren: () => import('./modules/messages.module').then((m) => m.MessagesModule),
        canActivate: [HeaderGuard],
        data: { headerColor: 'blue' }
    },
    {
        path: 'settings',
        loadChildren: () => import('./modules/settings.module').then((m) => m.SettingsModule),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'developmental-observations',
        loadChildren: () =>
            import('./modules/developmental-observation.module').then(
                (m) => m.DevelopmentalObservationModule
            ),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'journey-portfolios',
        loadChildren: () =>
            import('./modules/journey-portfolios.module').then(
                (m) => m.JourneyPortfoliosModule
            ),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'assessment-planning',
        loadChildren: () =>
            import('./modules/assessment-planning.module').then(
                (m) => m.AssessmentPlanningModule
            ),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: 'curriculum-plans',
        loadChildren: () =>
            import('./modules/curriculum-plans.module').then(
                (m) => m.CurriculumPlansModule
            ),
        canActivate: [HeaderGuard],
        data: { headerColor: 'white' }
    },
    {
        path: '**',
        redirectTo: 'login'
    }
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled',
            paramsInheritanceStrategy: 'always',
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
