import { ActionReducer, createReducer, on } from '@ngrx/store'
import { Attendance, AttendanceMap } from '../models/attendance'
import { CenterAttendance } from '../models/center-attedance'
import {
    loadAttendanceByDateSuccess,
    loadCenterAttendanceSuccess,
    resetEtaSubmitted,
    submitArrivalSuccess,
    submitAttendanceSuccess,
    deleteAbsence,
    toggleAttendanceCenterId
} from './attendance.actions'

export const attendanceFeatureKey = 'attendance'

export interface AttendanceState {
    attendanceList: CenterAttendance[]
    attendanceMap: AttendanceMap
    etaSubmitted: boolean
    submittedCenterIds: string[]
}

const initialState: AttendanceState = {
    attendanceList: [],
    attendanceMap: {},
    etaSubmitted: false,
    submittedCenterIds: []
}

export const AttendanceReducer: ActionReducer<AttendanceState> = createReducer(
    initialState,
    on(loadCenterAttendanceSuccess, (state: AttendanceState, { attendanceList, dependentId }) => {
        const removed = state.attendanceList.filter((a) => a.dependentId !== dependentId)
        const updated = removed.concat(attendanceList)

        return {
            ...state,
            attendanceList: updated
        }
    }),
    on(loadAttendanceByDateSuccess, (state: AttendanceState, { latestAttendance }) => {
        const attendanceMap = state.attendanceMap

        return {
            ...state,
            attendanceMap: {
                ...attendanceMap,
                [latestAttendance.dependentId]: latestAttendance
            }
        }
    }),
    on(submitAttendanceSuccess, (state: AttendanceState, { attendance }) => ({
        ...state,
        etaSubmitted: true,
        attendanceMap: attendance.transitions
            .map((t) => Attendance.fromParentAttendance(attendance, t))
            .reduce((acc, t) => ({ ...acc, [t.id]: t }), state.attendanceMap)
    })),
    on(submitArrivalSuccess, (state: AttendanceState) => ({
        ...state,
        etaSubmitted: true
    })),
    on(resetEtaSubmitted, (state: AttendanceState) => ({
        ...state,
        etaSubmitted: false
    })),
    on(deleteAbsence, (state: AttendanceState, { absence }) => {
        const attendanceMap = state.attendanceMap
        return {
            ...state,
            attendanceMap: {
                ...attendanceMap,
                [absence.dependentId]: {
                    ...attendanceMap[absence.dependentId],
                    isAbsentAction: false
                }
            }
        }
    }),
    on(toggleAttendanceCenterId, (state: AttendanceState, { centerId, remove }) => {
        const centerIds = state.submittedCenterIds
        let submittedCenterIds = [centerId, ...state.submittedCenterIds]
        if (centerIds.includes(centerId)) {
            if (remove) {
                submittedCenterIds = centerIds.filter((c) => c !== centerId)
            } else {
                submittedCenterIds = state.submittedCenterIds
            }
        }

        return {
            ...state,
            submittedCenterIds
        }
    })
)
