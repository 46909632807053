import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Store } from '@ngrx/store'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, tap, withLatestFrom } from 'rxjs/operators'
import { refreshData } from './refresh-action.actions'
import { RefreshActionState } from './refresh-action.reducers'

@Injectable()
export class RefreshActionEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger(RefreshActionEffects.name)
    }

    refreshData = createEffect(() => {
        return this.actions.pipe(
            ofType(refreshData),
            withLatestFrom(this.store),
            tap(() => {
                this.logger.debug(`Refreshing data.`)
            }),
            mergeMap(([, storeState]) => {
                const state = (storeState as any)['refresh-action'] as RefreshActionState
                return state.refreshActions
            })
        )
    })
}
