import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import moment from 'moment'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { calendarDataLoaded } from '@bh/design-system'
import { LoggerFactory, LoggerService } from '@bh/logging'

import { loadingDone, loadingStarted } from '../loading/loading.actions'
import { CalendarEntry } from '../models/calendar-entry'
import { CalendarService } from '../services/calendar.service'
import { DateUtil } from '../utils/date-util'
import { CalendarEntryType } from '../enums'
import { loadCalendar, loadCalendarError, loadCalendarSuccess } from './calendar.actions'

@Injectable()
export class CalendarEffects {
    private logger: LoggerService
    private futureEnrollmentEvents = [
        CalendarEntryType.STAFF_ANNOUNCEMENT,
        CalendarEntryType.CENTER_ANNOUNCEMENT,
        CalendarEntryType.CENTER_CLOSURE,
        CalendarEntryType.DUE_DATE,
        CalendarEntryType.SNACK_MENU,
        CalendarEntryType.CLASSROOM_ACTIVITY
    ]

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private calendarService: CalendarService,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger(CalendarEffects.name)
    }

    loadCalendarEntries$ = createEffect(() =>
        this.actions.pipe(
            ofType(loadCalendar),
            tap((guardianId) => {
                this.logger.debug(`Starting to load all calendar entries for ${guardianId}`)
                this.store.dispatch(loadingStarted({ model: 'Calendar' }))
            }),
            mergeMap(({ guardianId, startDate, futureEnrollment }) =>
                this.calendarService.getAllCalendarEntries(guardianId, startDate).pipe(
                    tap((calendarEntries) =>
                        this.logger.debug('Calendar entries loaded', calendarEntries)
                    ),
                    map((calendarEntries: CalendarEntry[]) => {
                        const dates: Date[] = []
                        if (futureEnrollment) {
                            calendarEntries = calendarEntries.filter(entry => this.futureEnrollmentEvents.includes(entry.entryType))
                        }
                        calendarEntries.forEach((c) => {
                
                            const numDays = DateUtil.getNumDaysInDateRange(
                                c.startDate.toISOString(),
                                c.endDate.toISOString()
                            )
                            let index = 0
                            let date = c.startDate
                            while (index < numDays + 1) {
                                dates.push(date)
                                date = (moment(date).add(1, 'd').toDate())
                                index++
                            }
                        })
                        this.store.dispatch(calendarDataLoaded({ dates }))
                        this.store.dispatch(loadingDone({ model: 'Calendar' }))
                        return loadCalendarSuccess({ calendarEntries })
                    }),
                    catchError((error: Error) => {
                        this.logger.error('Error loading calendar', error.message, error)
                        this.store.dispatch(loadingDone({ model: 'Calendar' }))
                        return of(loadCalendarError({ error }))
                    })
                )
            )
        )
    )
}
