import { Injectable } from '@angular/core'
import { FullstoryService, LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import moment from 'moment'
import { combineLatest, of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { loadActivityFeed } from '../dependant/dependant.actions'
import { ArrivalNotesService } from '../services/arrival-notes.service'
import {
    deleteArrivalNote,
    deleteArrivalNoteError,
    deleteArrivalNoteSuccess,
    loadFrequentEntry,
    loadFrequentEntryError,
    loadFrequentEntrySuccess,
    submitArrivalNotes,
    submitArrivalNotesError,
    submitArrivalNotesSuccess,
    updateArrivalNote,
    updateArrivalNoteError,
    updateArrivalNoteSuccess
} from './arrival-notes.actions'
import { AnalyticsService } from '@bh/security'

@Injectable()
export class ArrivalNotesEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private arrivalNotesService: ArrivalNotesService,
        private fullStoryService: FullstoryService,
        private analyticsService: AnalyticsService
    ) {
        this.logger = this.loggerFactory.getLogger(ArrivalNotesEffects.name)
    }

    submitArrivalNotes = createEffect(() =>
        this.actions.pipe(
            ofType(submitArrivalNotes),
            tap(({ dependentId }) =>
                this.logger.debug(`Starting to submit arrival notes for ${dependentId}`)
            ),
            mergeMap(({ dependentId, arrivalNote, date }) =>
                this.arrivalNotesService.submitArrivalNotes(dependentId, arrivalNote).pipe(
                    tap(() => this.logger.debug('Arrival notes submitted with success')),
                    tap(() => this.fullStoryService.sendEvent('submit-arrival-notes', { dependentId, ...arrivalNote })),
                    tap(() => this.analyticsService.logEvent('form_submit_success', {form_name: 'arrival notes'})),
                    mergeMap(() => [
                        submitArrivalNotesSuccess(),
                        loadActivityFeed({ dependantId: dependentId, date: moment(date).toDate() })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error('Error submitting arrival notes', error.message, error)
                        return of(submitArrivalNotesError({ error }))
                    })
                )
            ),
        )
    )

    deleteArrivalNoteEntry = createEffect(() =>
        this.actions.pipe(
            ofType(deleteArrivalNote),
            tap(({ dependentId }) =>
                this.logger.debug(`Starting to delete arrival note entry for ${dependentId}`)
            ),
            mergeMap(({ dependentId, arrivalNoteId }) =>
                this.arrivalNotesService.deleteArrivalNoteEntry(dependentId, arrivalNoteId).pipe(
                    tap(() => this.logger.debug('Arrival notes entry deleted with success')),
                    tap(() => this.fullStoryService.sendEvent('delete-arrival-notes', { dependentId, arrivalNoteId })),
                    mergeMap(() => [
                        deleteArrivalNoteSuccess()
                    ]),
                    catchError((error: Error) => {
                        this.logger.error('Error deleting arrival note entry', error.message, error)
                        return of(deleteArrivalNoteError({ error }))
                    })
                )
            )
        )
    )

    updateArrivalNoteEntry = createEffect(() =>
        this.actions.pipe(
            ofType(updateArrivalNote),
            tap(({ dependentId }) =>
                this.logger.debug(`Starting to update arrival note entry for ${dependentId}`)
            ),
            mergeMap(({ dependentId, deletedNoteIds, updatedArrivalNote, changesFound, date }) => {
                return combineLatest(
                    deletedNoteIds.map((id) =>
                        this.arrivalNotesService.deleteArrivalNoteEntry(dependentId, id)
                    )
                ).pipe(map(() => ({ dependentId, updatedArrivalNote, changesFound, date })))
            }),
            mergeMap(({ dependentId, updatedArrivalNote, changesFound, date }) => {
                if (changesFound) {
                    return this.arrivalNotesService
                        .submitArrivalNotes(dependentId, updatedArrivalNote)
                        .pipe(
                            tap(() =>
                                this.logger.debug('Arrival notes entry updated with success')
                            ),
                            mergeMap(() => [
                                updateArrivalNoteSuccess(),
                                loadActivityFeed({
                                    dependantId: dependentId,
                                    date: moment(date).toDate()
                                })
                            ]),
                            catchError((error: Error) => {
                                this.logger.error(
                                    'Error deleting arrival note entry',
                                    error.message,
                                    error
                                )
                                return of(updateArrivalNoteError({ error }))
                            })
                        )
                } else {
                    return of(true).pipe(
                        mergeMap(() => [
                            updateArrivalNoteSuccess(),
                            loadActivityFeed({
                                dependantId: dependentId,
                                date: moment(date).toDate()
                            })
                        ])
                    )
                }
            })
        )
    )

    loadFrequentEntry = createEffect(() =>
        this.actions.pipe(
            ofType(loadFrequentEntry),
            tap(({ dependentId }) => {
                this.logger.debug(`loading arrival notes frequent entry times for ${dependentId}`)
            }),
            mergeMap(({ dependentId }) =>
                this.arrivalNotesService.getFrequentEntries(dependentId).pipe(
                    tap((frequentEntryTimes) => this.logger.debug('Arrival notes frequent entry times loaded with success', frequentEntryTimes)),
                    mergeMap((frequentEntryTimes) => {
                        return [
                            loadFrequentEntrySuccess({
                                frequentEntryTimes
                            })
                        ]
                    }),
                    catchError((error: Error) => {
                        this.logger.error('Error loading arrival notes frequent entry times', error.message, error)
                        return of(loadFrequentEntryError({ error }))
                    })
                )
            )
        )
    )
}
