import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { BaseSession, selectSession, UserSession } from '@bh/session'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
import { environment } from '../../environments/environment'

@Injectable()
export class APIKeyInterceptor implements HttpInterceptor {
    public static readonly API_KEY_HEADER = 'x-api-key'
    private logger: LoggerService

    constructor(private loggerFactory: LoggerFactory, private store: Store) {
        this.logger = this.loggerFactory.getLogger(APIKeyInterceptor.name)
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return request.url.indexOf(environment.config().security.backendHost) >= 0 &&
            environment
                .config()
                .security.backendHost.indexOf(environment.config().security.gatewayHost) < 0
            ? this.addAPIKeyToRequest(request, next, this.store.select(selectSession))
            : next.handle(request)
    }

    private addAPIKeyToRequest(
        request: HttpRequest<unknown>,
        next: HttpHandler,
        session$: Observable<UserSession>
    ): Observable<HttpEvent<unknown>> {
        this.logger.debug('Adding API Key to request', request.url)

        return session$.pipe(
            map((session) => session.auth),
            filter((auth) => !!auth),
            map((auth) => auth as BaseSession),
            switchMap((auth) => {
                const apiKey = JSON.parse(window.atob(auth.token.split('.')[1])).api_key
                const headers = new HttpHeaders({
                    ...request.headers.keys().reduce((acc, k) => {
                        acc[k] = request.headers.getAll(k)
                        return acc
                    }, {} as { [key: string]: any }),
                    [APIKeyInterceptor.API_KEY_HEADER]: apiKey
                })

                return next.handle(request.clone({ headers }))
            })
        )
    }
}
