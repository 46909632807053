import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of, zip } from 'rxjs'
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'

import { DailyReportService } from '../services/daily-report.service'
import { presentToast } from '../toast/toast.actions'
import {
    downloadDailyReport,
    generateDailyReportSignedUrl,
    generateDailyReportSignedUrlError,
    generateDailyReportSignedUrlSuccess,
    shareDailyReport
} from './daily-report.actions'
import { ToastLevel } from '../enums'

@Injectable()
export class DailyReportEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private dailyReportService: DailyReportService,
        private translateService: TranslateService
    ) {
        this.logger = this.loggerFactory.getLogger(DailyReportEffects.name)
    }

    handleDailyReportShare = createEffect(
        () =>
            this.actions.pipe(
                ofType(shareDailyReport),
                tap(({ report }) => this.logger.debug(`Sharing Daily Report PDF`, report)),
                switchMap(({ report }) => {
                    return from(this.dailyReportService.shareDailyReport(report)).pipe(
                        tap(() => {
                            this.logger.debug(`Daily Report PDF Share is complete`, report)
                        })
                    )
                })
            ),
        { dispatch: false }
    )

    handleDailyReportDownload = createEffect(() =>
        this.actions.pipe(
            ofType(downloadDailyReport),
            tap(({ report }) => this.logger.debug(`Downloading Daily Report PDF URL`, report)),
            switchMap(({ report }) => {
                return zip(
                    this.dailyReportService.downloadDailyReport(report),
                    this.translateService.get('dailyReport.downloadSuccess').toPromise()
                ).pipe(
                    filter(([downloadedUri]) => typeof downloadedUri === 'string'),
                    map(([, message]) =>
                        presentToast({
                            message,
                            level: ToastLevel.Info
                        })
                    )
                )
            })
        )
    )

    handleDailyReportSignedUrl = createEffect(() =>
        this.actions.pipe(
            ofType(generateDailyReportSignedUrl),
            tap(({ id }) => this.logger.debug(`Generating Daily Report PDF URL`, id)),
            switchMap(({ id }) => {
                return this.dailyReportService.generateDailyReportSignedUrl(id).pipe(
                    map((data) => {
                        return generateDailyReportSignedUrlSuccess({
                            id,
                            url: data.signed_url,
                            fileName: data.filename
                        })
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            'Error Generating Daily Report PDF URL',
                            error.message,
                            error
                        )
                        return of(generateDailyReportSignedUrlError({ error }))
                    })
                )
            })
        )
    )
}
