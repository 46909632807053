import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { NavigationStart, Router } from '@angular/router'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map, takeUntil, tap } from 'rxjs/operators'
import { clearLoading } from '@events'

@Injectable()
export class APICancelInterceptor implements HttpInterceptor {
    private logger: LoggerService

    constructor(
        private router: Router,
        private loggerFactory: LoggerFactory,
        private store: Store
    ) {
        this.logger = this.loggerFactory.getLogger(APICancelInterceptor.name)
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            takeUntil(
                this.router.events.pipe(
                    filter((e) => e instanceof NavigationStart && request.method === 'GET'),
                    map((e) => e as NavigationStart),
                    tap((e: NavigationStart) => {
                        this.store.dispatch(clearLoading())
                        this.logger.debug(
                            `Canceling API requests to ${request.url} after navigation to ${e.url} occurred.`
                        )
                    })
                )
            )
        )
    }
}
