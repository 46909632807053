import { createAction, props } from '@ngrx/store'
import { Absence, Attendance } from '../models/attendance'
import { AttendanceTransitionType } from '../models/attendance-transition'
import { CenterAttendance } from '../models/center-attedance'
import { ParentAttendance } from '../models/parent-attendance'

export const loadCenterAttendance = createAction(
    '[Attendance] Load Attendance',
    props<{ dependentId: string; dependentName: string; startDate: Date }>()
)

export const loadCenterAttendanceSuccess = createAction(
    '[Attendance] Load Attendance Success',
    props<{ attendanceList: CenterAttendance[]; dependentId: string }>()
)

export const loadCenterAttendanceError = createAction(
    '[Attendance] Load Attendance Error',
    props<{ error: Error }>()
)

export const submitAbsence = createAction(
    '[Attendance] Submit Attendance Absence',
    props<{ absence: Absence }>()
)

export const submitAbsenceSuccess = createAction(
    '[Attendance] Submit Attendance Absence Successfully'
)

export const submitAbsenceFailure = createAction(
    '[Attendance] Error Submitting Attendance Absence',
    props<{ error: Error }>()
)

export const updateAbsence = createAction(
    '[Attendance] Update Attendance Absence',
    props<{ absence: Absence }>()
)

export const updateAbsenceSuccess = createAction(
    '[Attendance] Update Attendance Absence Successfully'
)

export const updateAbsenceFailure = createAction(
    '[Attendance] Error Updating Attendance Absence',
    props<{ error: Error }>()
)

export const deleteAbsence = createAction(
    '[Attendance] Delete Attendance Absence',
    props<{ absence: Absence }>()
)

export const deleteAbsenceSuccess = createAction(
    '[Attendance] Delete Attendance Absence Successfully'
)

export const deleteAbsenceFailure = createAction(
    '[Attendance] Error Deleting Attendance Absence',
    props<{ error: Error }>()
)

export const loadAttendanceByDate = createAction(
    '[Attendance] Load Attendance by Date',
    props<{ dependentId: string; guardianId: string; date: Date }>()
)

export const loadAttendanceByDateSuccess = createAction(
    '[Attendance] Load Attendance by Date Success',
    props<{ latestAttendance: Attendance }>()
)

export const loadAttendanceByDateError = createAction(
    '[Attendance] Load Attendance by Date Error',
    props<{ error: Error }>()
)

export const submitAttendance = createAction(
    '[Attendance] Submit Attendance',
    props<{
        dependentId: string
        centerId: string
        guardianId: string
        action: AttendanceTransitionType
    }>()
)

export const submitAttendanceSuccess = createAction(
    '[Attendance] Submit Attendance Successfully',
    props<{ attendance: ParentAttendance; centerId: string }>()
)

export const submitAttendanceFailure = createAction(
    '[Attendance] Error Submitting Attendance',
    props<{ error: Error }>()
)

export const resetEtaSubmitted = createAction('[Arrival] Reset Eta Submitted')

export const submitArrival = createAction(
    '[Arrival] Submit Arrival',
    props<{
        isHere: boolean
        eta: string
        guardianId: string
        dependentId: string
        centerId: string
    }>()
)

export const submitArrivalSuccess = createAction(
    '[Arrival] Submit Arrival Successfully',
    props<{ eta: string }>()
)

export const submitArrivalError = createAction(
    '[Arrival] Error Submitting Arrival',
    props<{ error: Error }>()
)

export const toggleAttendanceCenterId = createAction(
    '[Arrival] Add Center Id',
    props<{ centerId: string; remove: boolean }>()
)
