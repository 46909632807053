import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { startLoading, stopLoading } from '@bh/security'
import { Attachment, selectBulkMedia } from '@events'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { TranslateService } from '@ngx-translate/core'
import { EMPTY, Observable, from, of } from 'rxjs'
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators'

import { FaceDetection } from '@bh/face-detection'
import { Share } from '@capacitor/share'
import { ShareActions, ToastLevel } from '../enums'
import { MediaLinkGenerateResponse } from '../interfaces/photo-manager'
import { BulkMediaEntry } from '../models/photo-manager'
import { BulkMediaDownloadService } from '../services/bulk-media-download.service'
import { MediaService } from '../services/media.service'
import { presentToast } from '../toast/toast.actions'
import {
    linkGenerationProcess,
    linkGenerationProcessError,
    linkGenerationProcessSuccess,
    recentDownloadItemsRequest,
    recentDownloadItemsRequestError,
    recentDownloadItemsRequestSuccess,
    setSelectedDownloadId,
    shareBulkLink,
    submitGenerateLinkRequest,
    submitGenerateLinkRequestError
} from './bulk-media-download.actions'

@Injectable()
export class BulkMediaDownloadEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private bulkMediaDownloadService: BulkMediaDownloadService,
        private store: Store,
        private mediaService: MediaService,
        private translate: TranslateService
    ) {
        this.logger = this.loggerFactory.getLogger(BulkMediaDownloadEffects.name)
    }

    submitGenerateLinkRequest = createEffect(() =>
        this.actions.pipe(
            ofType(submitGenerateLinkRequest),
            tap(({ guardianId }) =>
                this.logger.debug(`Starting to submit generate link request for ${guardianId}`)
            ),
            mergeMap(({ guardianId, filter }) =>
                this.bulkMediaDownloadService.submitBulkMediaLinkGenerate(guardianId, filter).pipe(
                    tap(() => {
                        this.logger.debug('Generate link request submitted with success')
                    }),
                    mergeMap((data: MediaLinkGenerateResponse) => {
                        return [setSelectedDownloadId({ selectedDownloadId: data.id })]
                    }),
                    catchError((error: Error) => {
                        this.logger.error(
                            'Error submitting generate link request',
                            error.message,
                            error
                        )
                        return of(submitGenerateLinkRequestError({ error }))
                    })
                )
            )
        )
    )

    submitGenerateLinkRequestError$ = createEffect(() =>
        this.actions.pipe(
            ofType(submitGenerateLinkRequestError),
            switchMap(() => of(stopLoading({ action: '[Bulk Media] Link generation process' })))
        )
    )

    linkGenerationProcess = createEffect(() =>
        this.actions.pipe(
            ofType(linkGenerationProcess),
            tap(({ guardianId }) =>
                this.logger.debug(`Starting to submit generate link request for ${guardianId}`)
            ),
            mergeMap(({ guardianId, mediaId }) =>
                this.bulkMediaDownloadService
                    .bulkMediaLinkGenerationProcess(guardianId, mediaId)
                    .pipe(
                        tap(() => {
                            this.logger.debug('Generate link request submitted with success')
                        }),
                        mergeMap((data: BulkMediaEntry) => {
                            return [linkGenerationProcessSuccess({ linkGenerationResponse: data })]
                        }),
                        catchError((error: Error) => {
                            this.logger.error(
                                'Error submitting generate link request',
                                error.message,
                                error
                            )
                            return of(linkGenerationProcessError({ error }))
                        })
                    )
            )
        )
    )

    recentDownloadItemsRequest = createEffect(() =>
        this.actions.pipe(
            ofType(recentDownloadItemsRequest),
            tap(() => this.logger.debug('Starting to load all media')),
            mergeMap(({ guardianId }) =>
                this.bulkMediaDownloadService.bulkRecentDownloads(guardianId).pipe(
                    tap(() => {
                        this.logger.debug('Getting all recent downloads with success')
                    }),
                    mergeMap((data: any[]) => {
                        const recentDownlaod = data?.map(
                            (recentItem) => new BulkMediaEntry(recentItem)
                        )
                        return [
                            recentDownloadItemsRequestSuccess({
                                recentDownload: recentDownlaod ?? []
                            })
                        ]
                    }),
                    catchError((error: Error) => {
                        this.logger.error('Error getting recent downloads', error.message, error)
                        return of(recentDownloadItemsRequestError({ error }))
                    })
                )
            )
        )
    )
    shareBulkLink$ = createEffect(() =>
        this.actions.pipe(
            ofType(shareBulkLink),
            concatLatestFrom(
                () => this.store.select(selectBulkMedia) as Observable<BulkMediaEntry>
            ),
            switchMap(([payload, bulkMediaEntry]) => {
                this.store.dispatch(startLoading({ action: payload.loader }))
                return this.getBulkMedia(bulkMediaEntry.attachmentId as string).pipe(
                    tap((attachment) =>
                        this.logger.debug(
                            `Starting to ${payload.action} bulk link: ${attachment.fileName}`
                        )
                    ),
                    switchMap((attachment) => {
                        let bulkAction$: Observable<any>
                        if (payload.action === ShareActions.Download) {
                            bulkAction$ = from(
                                FaceDetection.storeFile({
                                    fileName: attachment.fileName,
                                    fileUrl: attachment.url
                                })
                            ).pipe(
                                map((result) => ({ attachment, filePath: result.filePath })),
                                tap(({ filePath }) =>
                                    this.logger.debug(`Bulk file downloaded to ${filePath}`)
                                )
                            )
                        } else {
                            bulkAction$ = from(this.shareUrl(attachment))
                        }
                        return bulkAction$.pipe(map(() => ({ attachment })))
                    }),
                    tap(({ attachment }) => {
                        this.logger.debug(
                            `Bulk file ${attachment.fileName} ${payload.action} was successful`
                        )
                    }),
                    switchMap(() =>
                        payload.action === ShareActions.Download
                            ? this.translate.get('photo-manager.download-success').pipe(
                                  map((successMessage) =>
                                      presentToast({
                                          message: successMessage,
                                          level: ToastLevel.Success
                                      })
                                  )
                              )
                            : EMPTY
                    ),
                    finalize(() => this.store.dispatch(stopLoading({ action: payload.loader }))),
                    catchError((e) => {
                        this.logger.error(e.message)
                        return payload.action === ShareActions.Download
                            ? this.translate.get('photo-manager.download-error').pipe(
                                  map((errorMessage) =>
                                      presentToast({
                                          message: errorMessage,
                                          level: ToastLevel.Error
                                      })
                                  )
                              )
                            : EMPTY
                    })
                )
            })
        )
    )

    private getBulkMedia(attachmentId: string): Observable<Attachment> {
        this.logger.debug(`Starting to load media for id ${attachmentId}`)
        return this.mediaService.getMediaUrl(attachmentId, false).pipe(
            tap((media) => this.logger.debug('Media URL loaded', media)),
            catchError(() => {
                throw new Error('Error loading media')
            })
        )
    }

    private async shareUrl(attachment: Attachment): Promise<void | Error> {
        try {
            await Share.share({
                text: attachment.url
            })
        } catch (e) {
            throw new Error(`Bulk file share failed: ${e}`)
        }
    }
}
