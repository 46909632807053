import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { Profile } from '../models/profile'
import { ProfileService } from '../services/profile.service'
import {
    loadAccountMetaData,
    loadAccountMetaDataError,
    loadAccountMetaDataSuccess,
    loadProfile,
    loadProfileError,
    loadProfileSuccess
} from './profile.actions'
import { AccountMetaData } from '../models/account-meta-data'

@Injectable()
export class ProfileEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private profileService: ProfileService
    ) {
        this.logger = this.loggerFactory.getLogger(ProfileEffects.name)
    }

    loadProfile = createEffect(() =>
        this.actions.pipe(
            ofType(loadProfile),
            tap(() => this.logger.debug(`Starting to load profile for logged user`)),
            mergeMap(() =>
                this.profileService.loadProfile().pipe(
                    tap((profile) => this.logger.debug('Profile loaded', profile)),
                    map((profile: Profile) => loadProfileSuccess({ profile })),
                    catchError((error: Error) => {
                        this.logger.error('Error loading profile', error.message, error)
                        return of(loadProfileError({ error }))
                    })
                )
            )
        )
    )

    loadAccountMetaData = createEffect(() =>
        this.actions.pipe(
            ofType(loadAccountMetaData),
            tap(() => this.logger.debug(`Starting to load account metadata`)),
            mergeMap(() =>
                this.profileService.getChildAccountMetaData().pipe(
                    tap((metaData) => this.logger.debug('Account Meta Data loaded', metaData)),
                    map((metaData: AccountMetaData) => loadAccountMetaDataSuccess({ metaData })),
                    catchError((error: Error) => {
                        this.logger.error('Error loading metadata', error.message, error)
                        return of(loadAccountMetaDataError({ error }))
                    })
                )
            )
        )
    )
}
