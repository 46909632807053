import { Injectable } from '@angular/core'
import { documentsClicked } from '@bh/design-system'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { ShareabilityService } from '../services/shareability.service'
import {
    documentRouted,
    getMemoryShareability,
    getMemoryShareabilityError,
    getMemoryShareabilitySuccess
} from './memories.actions'

@Injectable()
export class MemoriesEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private shareabilityService: ShareabilityService
    ) {
        this.logger = this.loggerFactory.getLogger(MemoriesEffects.name)
    }

    getMemoryShareability = createEffect(() =>
        this.actions.pipe(
            ofType(getMemoryShareability),
            tap(({ memoryId, memoryType }) =>
                this.logger.debug(`Loading shareability of memory type:${memoryType} ${memoryId}`)
            ),
            mergeMap(({ guardianId, memoryId, memoryType, activityType }) => {
                if (memoryType === 'snapshot' && activityType !== 'observation') {
                    return this.shareabilityService
                        .getSnapshotShareability(guardianId, memoryId)
                        .pipe(
                            tap((data) => this.logger.debug('Shareability loaded', data)),
                            map((data) => {
                                return getMemoryShareabilitySuccess({
                                    isShareable: data.isShareable
                                })
                            }),
                            catchError((error: Error) => {
                                this.logger.error(
                                    'Error loading shareability',
                                    error.message,
                                    error
                                )
                                return of(getMemoryShareabilityError({ error }))
                            })
                        )
                }

                return this.shareabilityService
                    .getObservationShareability(guardianId, memoryId)
                    .pipe(
                        tap((data) => this.logger.debug('Shareability loaded', data)),
                        map((data) => {
                            return getMemoryShareabilitySuccess({
                                isShareable: data.isShareable
                            })
                        }),
                        catchError((error: Error) => {
                            this.logger.error('Error loading shareability', error.message, error)
                            return of(getMemoryShareabilityError({ error }))
                        })
                    )
            })
        )
    )

    handleDocumentNoteClick = createEffect(() =>
        this.actions.pipe(
            ofType(documentsClicked),
            tap(({ id, selection }) => this.logger.debug(`Navigating to note`, id, selection)),
            filter((data) => data.selection !== 'portfolio'),
            switchMap(() => {
                return of(true).pipe(
                    tap((navigated) => this.logger.debug(`Navigation result: ${navigated}`)),
                    map(() => documentRouted())
                )
            })
        )
    )
}
