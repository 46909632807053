import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { AlertsService } from '../services/alerts.service'
import {
    loadEmergencyAlerts,
    loadEmergencyAlertsError,
    loadEmergencyAlertsSuccess
} from './emergency-alerts.actions'

@Injectable()
export class AlertEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private alertsService: AlertsService
    ) {
        this.logger = this.loggerFactory.getLogger(AlertEffects.name)
    }

    loadEmergencyAlerts = createEffect(() =>
        this.actions.pipe(
            ofType(loadEmergencyAlerts),
            tap(() => this.logger.debug('Starting to load emergency alerts')),
            mergeMap(() =>
                this.alertsService.getEmergencyAlerts().pipe(
                    tap((emergencyAlerts) =>
                        this.logger.debug('Emergency alerts loaded', emergencyAlerts)
                    ),
                    map((emergencyAlerts: any[]) =>
                        loadEmergencyAlertsSuccess({ emergencyAlerts })
                    ),
                    catchError((error: Error) => {
                        this.logger.error('Error loading emergency alerts', error.message, error)
                        return of(loadEmergencyAlertsError({ error }))
                    })
                )
            )
        )
    )
}
