import { Injectable } from '@angular/core'
import { FullstoryService, LoggerFactory, LoggerService } from '@bh/logging'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { EMPTY, forkJoin, of } from 'rxjs'
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { loadingDone, loadingStarted } from '../loading/loading.actions'
import { CenterAttendance } from '../models/center-attedance'
import { AttendanceService } from '../services/attendance.service'
import {
    deleteAbsence,
    deleteAbsenceFailure,
    deleteAbsenceSuccess,
    loadAttendanceByDate,
    loadAttendanceByDateError,
    loadAttendanceByDateSuccess,
    loadCenterAttendance,
    loadCenterAttendanceError,
    loadCenterAttendanceSuccess,
    submitAbsence,
    submitAbsenceFailure,
    submitAbsenceSuccess,
    submitArrival,
    submitArrivalError,
    submitArrivalSuccess,
    submitAttendance,
    submitAttendanceFailure,
    submitAttendanceSuccess,
    updateAbsence,
    updateAbsenceFailure,
    updateAbsenceSuccess
} from './attendance.actions'
import { AttendanceUtil } from '../utils/attendance-util'

@Injectable()
export class AttendanceEffects {
    private logger: LoggerService

    constructor(
        private loggerFactory: LoggerFactory,
        private actions: Actions,
        private attendanceService: AttendanceService,
        private store: Store,
        private fullStoryService: FullstoryService
    ) {
        this.logger = this.loggerFactory.getLogger(AttendanceEffects.name)
    }

    loadAttendance = createEffect(() =>
        this.actions.pipe(
            ofType(loadCenterAttendance),
            tap((dependentId) => {
                this.logger.debug(`Starting to load attendance info for ${dependentId}`)
                this.store.dispatch(loadingStarted({ model: 'CenterAttendance' }))
            }),
            mergeMap(({ dependentId, dependentName, startDate }) =>
                this.attendanceService.getAttendance(dependentId, dependentName, startDate).pipe(
                    tap((attendanceItems) =>
                        this.logger.debug('Attendance loaded', attendanceItems)
                    ),
                    mergeMap((attendanceList: CenterAttendance[]) => [
                        loadCenterAttendanceSuccess({ attendanceList, dependentId }),
                        loadingDone({ model: 'CenterAttendance' })
                    ]),
                    catchError((error: Error) => {
                        this.logger.error('Error loading attendance', error.message, error)
                        this.store.dispatch(loadingDone({ model: 'CenterAttendance' }))
                        return of(loadCenterAttendanceError({ error }))
                    })
                )
            )
        )
    )

    submitAbsence = createEffect(() =>
        this.actions.pipe(
            ofType(submitAbsence),
            tap((absence: any) =>
                this.logger.debug(
                    `Starting to submit absence for child ${absence.dependentId}`,
                    absence
                )
            ),
            mergeMap(({ absence }) =>
                this.attendanceService.submitAbsence(absence).pipe(
                    tap(() => this.logger.debug('Absence submitted successfully')),
                    map(() => submitAbsenceSuccess()),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error submitting absence for child ${absence.dependentId}`,
                            error.message,
                            error
                        )
                        return of(submitAbsenceFailure({ error }))
                    })
                )
            )
        )
    )

    updateAbsence = createEffect(() =>
        this.actions.pipe(
            ofType(updateAbsence),
            tap((absence: any) =>
                this.logger.debug(
                    `Starting to update absence for child ${absence.dependentId}`,
                    absence
                )
            ),
            mergeMap(({ absence }) =>
                this.attendanceService.updateAbsence(absence).pipe(
                    tap(() => this.logger.debug('Absence updated successfully')),
                    map(() => updateAbsenceSuccess()),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error updating absence for child ${absence.dependentId}`,
                            error.message,
                            error
                        )
                        return of(updateAbsenceFailure({ error }))
                    })
                )
            )
        )
    )

    deleteAbsence = createEffect(() =>
        this.actions.pipe(
            ofType(deleteAbsence),
            tap((absence: any) =>
                this.logger.debug(
                    `Starting to delete absence for child ${absence.dependentId}`,
                    absence
                )
            ),
            mergeMap(({ absence }) =>
                this.attendanceService.deleteAbsence(absence).pipe(
                    tap(() => this.logger.debug('Absence deleted successfully')),
                    map(() => deleteAbsenceSuccess()),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error deleting absence for child ${absence.dependentId}`,
                            error.message,
                            error
                        )
                        return of(deleteAbsenceFailure({ error }))
                    })
                )
            )
        )
    )

    loadAttendanceByDate = createEffect(() =>
        this.actions.pipe(
            ofType(loadAttendanceByDate),
            tap(({ dependentId, date }) =>
                this.logger.debug(
                    `Starting to load attendance info for ${dependentId} for data ${date}`
                )
            ),
            mergeMap(({ dependentId, guardianId, date }) =>
                forkJoin([
                    this.attendanceService.getAttendanceFromParentByDate(dependentId, date),
                    this.attendanceService.getAttendanceFromCenterByDate(dependentId, date),
                    this.attendanceService.getArrival(guardianId, dependentId)
                ]).pipe(
                    tap(([attendanceFromParent, attendanceFromCenter, arrival]) => {
                        this.logger.debug(
                            'Attendance loaded by date',
                            attendanceFromParent,
                            attendanceFromCenter,
                            arrival
                        )
                    }),
                    map(
                        ([attendanceFromParent, attendanceFromCenter, arrival]) =>
                            AttendanceUtil.getDependentMatchedAttendances(
                                attendanceFromParent,
                                attendanceFromCenter,
                                arrival
                            ).slice(-1)[0]
                    ),
                    switchMap((latestAttendance) =>
                        latestAttendance
                            ? of(loadAttendanceByDateSuccess({ latestAttendance }))
                            : EMPTY
                    ),
                    catchError((error: Error) => {
                        this.logger.error('Error loading attendance', error.message, error)
                        return of(loadAttendanceByDateError({ error }))
                    })
                )
            )
        )
    )

    submitAttendance = createEffect(() =>
        this.actions.pipe(
            ofType(submitAttendance),
            tap(({ dependentId, action }) =>
                this.logger.debug(`Starting to submit attendance for child ${dependentId}`, action)
            ),
            mergeMap(({ dependentId, guardianId, action, centerId }) =>
                this.attendanceService.submitAttendance(dependentId, guardianId, action).pipe(
                    tap(() => this.logger.debug('Attendance submitted successfully')),
                    tap(() =>
                        this.fullStoryService.sendEvent('attendance-submited', {
                            dependentId,
                            guardianId,
                            action
                        })
                    ),
                    map((attendance) => submitAttendanceSuccess({ attendance, centerId })),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error submitting attendance for child ${dependentId}`,
                            error.message,
                            error
                        )
                        return of(submitAttendanceFailure({ error }))
                    })
                )
            )
        )
    )

    submitArrival = createEffect(() =>
        this.actions.pipe(
            ofType(submitArrival),
            tap((arrival: any) =>
                this.logger.debug(
                    `Starting to submit arrival for child ${arrival.dependentId}`,
                    arrival
                )
            ),
            mergeMap(({ isHere, eta, guardianId, dependentId }) =>
                this.attendanceService.submitArrival(isHere, eta, guardianId, dependentId).pipe(
                    tap(() => this.logger.debug('Arrival submitted successfully')),
                    map(() => submitArrivalSuccess({ eta })),
                    catchError((error: Error) => {
                        this.logger.error(
                            `Error submitting arrival for child ${dependentId}`,
                            error.message,
                            error
                        )
                        return of(submitArrivalError({ error }))
                    })
                )
            )
        )
    )
}
