import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { RegisterPIIKey } from '@bh/session'
import { FullstoryService, LoggerFactory } from '@bh/logging'
import {
    AbstractAuthenticationService,
    JwtToken,
    NotificationService,
    SecurityConfig,
    selectBiometrics
} from '@bh/security'
import { App } from '@capacitor/app'
import { Device, DeviceInfo } from '@capacitor/device'
import { Preferences } from '@capacitor/preferences'
import { Store } from '@ngrx/store'
import { from, Observable, combineLatest } from 'rxjs'
import { map, switchMap, take, tap } from 'rxjs/operators'
import { ParentAuthentication } from '../models/parent-authentication.model'
import { ParentAppVersionService } from './parent-app-version.service'

@Injectable({
    providedIn: 'root'
})
export class ParentAuthenticationService extends AbstractAuthenticationService<ParentAuthentication> {
    constructor(
        private readonly loggerFactory: LoggerFactory,
        protected readonly http: HttpClient,
        protected readonly config: SecurityConfig,
        protected readonly notifications: NotificationService,
        protected readonly appVersionService: ParentAppVersionService,
        protected readonly store: Store,
        protected readonly fullStoryService: FullstoryService
    ) {
        super(
            http,
            config,
            loggerFactory.getLogger(ParentAuthenticationService.name),
            notifications,
            store,
            fullStoryService
        )
    }

    protected validateCredentials(
        username: string,
        password: string
    ): Observable<ParentAuthentication> {
        this.logger.debug('Get token', username)
        this.fullStoryService.logger('log', `Get the token and validate credentials for ${username}`)

        const crendential = { username, password }
        RegisterPIIKey(crendential, 'password')

        return this.http.post<JwtToken>(`${this.config.gatewayHost}/auth/parent`, crendential).pipe(
            tap(() => {
                Preferences.set({
                    key: ParentAuthenticationService.STORAGE_REFRESH_URL,
                    value: `${this.config.gatewayHost}/auth/parent`
                })
            }),
            switchMap((data) => {
                return this.appVersionService.getVersion().pipe(
                    map((version: string) => {
                        const decodedToken = this.decodeJwtToken(data.accessToken)

                        this.fullStoryService.logger('log', `Refreshed Auth token and returning ParentAuthentication for ${username}`)
                        return new ParentAuthentication(
                            data.accessToken,
                            decodedToken?.api_key,
                            decodedToken?.sa_id,
                            username,
                            version
                        )
                    })
                )
            })
        )
    }

    protected registerDevice(
        token: string | null,
        uuid: string,
        deviceInfo: DeviceInfo
    ): Observable<string> {
        const apnsEnv = this.config.production ? 'apns' : 'apns_sandbox'
        const selectBiometrics$ = this.store.select(selectBiometrics)

        return combineLatest([selectBiometrics$, from(App.getInfo())]).pipe(
            take(1),
            switchMap((dataArr) => {
                const biometricsEnabled = dataArr[0].isEnabled
                const appVersion = dataArr[1].version
                const bundleId = dataArr[1].id
                return this.http
                    .post(`${this.config.gatewayHost}/parent/device`, {
                        device_id: uuid,
                        timezone: 'UTC',
                        locale: 'UTC',
                        mac_address: '',
                        model: deviceInfo.model,
                        platform_name: deviceInfo.platform,
                        os_name:
                            deviceInfo.operatingSystem.toLowerCase() === 'android'
                                ? 'Android'
                                : 'iOS',
                        os_type: '64bit',
                        os_version: deviceInfo.osVersion,
                        available_memory: deviceInfo.memUsed || 0,
                        app: 'parent',
                        app_version: appVersion,
                        push_service:
                            deviceInfo.operatingSystem.toLowerCase() === 'android'
                                ? 'fcm'
                                : apnsEnv,
                        push_token: token,
                        bio_enabled: biometricsEnabled,
                        bundle_id: bundleId
                    })
                    .pipe(map(() => uuid))
            })
        )
    }

    public logout(): Observable<void> {
        return from(Device.getId()).pipe(
            switchMap((res) => {
                let params = new HttpParams()
                if (res.uuid) {
                    params = params.append('device_id', res.uuid)
                }

                return this.http
                    .get(`${this.config.gatewayHost}/parent/auth/logout`, {
                        params
                    })
                    .pipe(
                        map(() => {
                            return
                        })
                    )
            })
        )
    }
}
