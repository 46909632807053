import { createFeatureSelector, createSelector } from '@ngrx/store'
import { CalendarEntry } from '../models/calendar-entry'
import { DateUtil } from '../utils/date-util'
import { calendarFeatureKey, CalendarState } from './calendar.reducers'
import moment from 'moment'

const calendarSelector = createFeatureSelector<CalendarState>(calendarFeatureKey)

export const selectCalendarEntries = createSelector(
    calendarSelector,
    (state: CalendarState): CalendarEntry[] => state.calendarEntries
)

export const selectCalendarEntriesInRange = (startDate: Date, endDate: Date): any =>
    createSelector(calendarSelector, (state: CalendarState) => {
        const result = state.calendarEntries.filter((c) => {
            if (c.entryType !== 'please_bring') {
                return (
                    DateUtil.isBetween(c.startDate, startDate, endDate) ||
                    DateUtil.isBetween(c.endDate, startDate, endDate) ||
                    DateUtil.isBetween(startDate, c.startDate, c.endDate)
                )
            } else {
                // Only show when please bring date matches calendar date
                const pickerStart = moment(startDate).format('YYYY-MM-DD')
                const bringStart = moment(c.startDate).format('YYYY-MM-DD')
                return pickerStart === bringStart
            }
        }).sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
        return result
    })

export const selectCalendarminDate = createSelector(
    calendarSelector,
    (state: CalendarState) => state.minDate
)

export const selectCalendarStatus = createSelector(
    calendarSelector,
    (state: CalendarState) => state.calendarActive
)
