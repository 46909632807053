import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LoggerFactory, LoggerService } from '@bh/logging'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../../app/src/environments/environment'
import { Attachment, MEDIA_TTL } from '@events'
import { CacheableMedia } from '@bh/data'

@Injectable({
    providedIn: 'root'
})
export class MediaService {
    private logger: LoggerService

    constructor(private httpClient: HttpClient, private loggerFactory: LoggerFactory) {
        this.logger = this.loggerFactory.getLogger(MediaService.name)
    }

    public getMediaUrl(mediaId: string, thumbnail = false): Observable<Attachment> {
        return this._getAllAttachments([mediaId], thumbnail).pipe(
            map((attachments) => attachments[0])
        )
    }

    public getAllAttachments(
        thumbnail: boolean,
        attachmentIds: Array<string>,
    ): Observable<Attachment[]> {
        return this._getAllAttachments(attachmentIds, thumbnail)
    }

    @CacheableMedia()
    private _getAllAttachments(
        attachmentIds: Array<string>,
        thumbnail: boolean = false
    ): Observable<Attachment[]> {
        const payload = {
            mediaids: attachmentIds,
            thumbnail
        }

        return this.httpClient
            .post(`${environment.config().security.backendHost}/medias`, payload)
            .pipe(map((data: any) => this.parseAllAttachments(data, MEDIA_TTL, thumbnail)))
    }

    private parseAllAttachments(data: any, ttl?: number, isThumbnail?: boolean): Attachment[] {
        const isThumbnailInt = isThumbnail ? 1 : 0
        const isValidMedias = !!data && !!data.medias
        if (isValidMedias) {
            const attachments = data.medias
            return Object.keys(attachments).reduce((acc: Attachment[], key: string) => {
                if (!Object.prototype.hasOwnProperty.call(attachments[key], 'error')) {
                    acc = [
                        ...acc,
                        new Attachment(
                            {
                                attachmentId: key,
                                ...attachments[key]
                            },
                            isThumbnailInt,
                            attachments[key].ttl ? attachments[key].ttl * 1000 : ttl
                        )
                    ]
                } else {
                    acc = [
                        ...acc,
                        new Attachment(
                            {
                                attachmentId: key,
                                signed_url: 'assets/memories-placeholder.jpg',
                                mime_type: 'image/jpeg'
                            },
                            isThumbnailInt,
                            attachments[key]?.mime_type ? -1 : undefined
                        )
                    ]
                }
                return acc
            }, Array<Attachment>())
        } else {
            return []
        }
    }
}
